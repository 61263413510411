var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.upload-text", { text: _vm.$t("lbl_data") })
      }
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            layout: "vertical",
            "label-col": { span: 6 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_feature") } },
            [
              _c("SelectUploadDataFeature", {
                model: {
                  value: _vm.feature,
                  callback: function($$v) {
                    _vm.feature = $$v
                  },
                  expression: "feature"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 6 } } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.downloadTemplate,
                    "data-testid": "upload-data-download"
                  },
                  on: { click: _vm.downloadTemplate }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("common.download-text", {
                        text: _vm.$t("lbl_template")
                      })
                    ) + " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_upload_file"),
                help: _vm.$t("lbl_max_upload")
              }
            },
            [
              _c(
                "a-upload-dragger",
                {
                  attrs: {
                    name: "file",
                    multiple: false,
                    accept: ".xlsx",
                    "before-upload": function() {
                      return false
                    },
                    remove: _vm.handleRemove,
                    "file-list": _vm.fileList,
                    "data-testid": "upload-data-dragger"
                  },
                  on: { change: _vm.handleChange }
                },
                [
                  _c(
                    "p",
                    { staticClass: "ant-upload-drag-icon" },
                    [_c("a-icon", { attrs: { type: "inbox" } })],
                    1
                  ),
                  _c("p", { staticClass: "ant-upload-text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lbl_upload_drag_and_drop")) + " "
                    )
                  ]),
                  _c("p", { staticClass: "ant-upload-hint" }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_support_upload")) + " ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.uploadData,
                        "data-testid": "upload-data-upload"
                      },
                      on: { click: _vm.uploadFile }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_upload_file")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }