































































import SelectUploadDataFeature from "@/components/custom/select/SelectUploadDataFeature.vue";
import { useBlob, useUploadData } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { EnumUploadDataFeature } from "@/models/enums/UploadDataFeature.enum";
import { UploadFile } from "ant-design-vue/types/upload";
import { Component, Vue } from "vue-property-decorator";
@Component({
  mixins: [MNotificationVue],
  components: {
    SelectUploadDataFeature,
  },
})
export default class UploadData extends Vue {
  fileList: UploadFile[] = [];

  feature: EnumUploadDataFeature | null = null;

  loading = {
    downloadTemplate: false,
    uploadData: false,
  };

  downloadTemplate(): void {
    if (!this.feature) {
      this.showNotifError("lbl_feature_required");
      return;
    }

    const { downloadTemplate } = useUploadData();
    const { toDownload } = useBlob();

    this.loading.downloadTemplate = true;
    downloadTemplate(this.feature)
      .then(res => {
        toDownload(res, `template_${String(this.feature).toLowerCase()}.xlsx`);
      })
      .finally(() => {
        this.loading.downloadTemplate = false;
      });
  }

  uploadFile(): void {
    if (!this.feature) {
      this.showNotifError("lbl_feature_required");
      return;
    }

    const file = this.fileList[0];

    if (!file) {
      this.showNotifError("lbl_file_required");
      return;
    }

    const MAX_SIZE = 5242880;
    if (file.size >= MAX_SIZE) {
      this.showNotifError("lbl_message_file_size_exceeded", {
        fileSize: 5,
      });
      return;
    }

    const { uploadData } = useUploadData();
    this.loading.uploadData = true;

    const formData = new FormData();
    formData.append("file", file as unknown as Blob);
    uploadData(this.feature, formData)
      .then(() => {
        this.showNotifSuccess("notif_upload_file_success");
        this.handleRemove();
      })
      .finally(() => {
        this.loading.uploadData = false;
      });
  }

  handleRemove(): void {
    this.fileList = [];
  }

  handleChange(info) {
    if (info.file.status === "removed") {
      this.fileList = [];
    } else {
      this.fileList = [info.file];
    }
  }
}
